<template>
  <!--div>
    <v-tooltip bottom>
      <template v-slot:activator="{on, attrs}"-->
        <v-img
          class="text-center"
          :src="iconURL"
          alt=""
          contain
          style="height: 30px !important; width: 30px !important;"/>
      <!--/template>
      <span style="font-size: 14px;">{{$t(`view.modes.transports.${mode.name}`)}}</span>
    </v-tooltip>
  </div-->
</template>

<script>
export default {
  name: "IconModeSimple",

  props: {
    mode: {type: Object, default: () => null},
    enabled: {type: Boolean, default: false}
  },

  computed: {

    iconURL() {
      return require(`@/assets/icons/modes/${this.enabled ? this.mode.icon_enabled : this.mode.icon_disabled}`);
    },
  }
}
</script>

<style scoped>

</style>